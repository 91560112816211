<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="480" overlay-opacity="0.5" id="chat">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <v-img
            width="auto"
            max-width="100"
            :src="require('@/assets/tutorial/chat_1.png')"
            class="mb-3"
            contain
          >
          </v-img>
          <v-img
            width="auto"
            max-width="100"
            :src="require('@/assets/tutorial/chat_3.png')"
            class="mb-3"
            contain
          >
          </v-img>
        </div>
        <div class="d-flex">
          <v-img
            width="auto"
            max-width="100"
            :src="require('@/assets/tutorial/chat_4.png')"
            class="mb-3"
            contain
          >
            <v-overlay :value="dialog" opacity="0.6" />
          </v-img>
          <v-img
            width="auto"
            max-width="100"
            :src="require('@/assets/tutorial/chat_2.png')"
            class="mb-3"
            contain
          >
          </v-img>
        </div>
      </div>

      <div style="position: relative">
        <span class="char-name">Hamochi</span>
      </div>

      <v-card class="align-self-end chat-plot">
        <v-card-text class="text-h6 py-0 my-0">
          This is a magical place! ✨✨✨
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn class="text-lowercase" text @click="dialog = false">
            {{ $t("action.clickToContinue") }} ▼
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    dialog: true,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
/* .v-dialog__content {
  align-items: flex-end;
}
.v-dialog {
  box-shadow: none;
} */

.char-name {
  background: rgb(255, 246, 152) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset !important;
  border-radius: 10px !important;
  top: 0;
  left: 20px;
  position: absolute;
  width: fit-content;
  z-index: 9999;
  padding: 5px 20px;
}

.chat-plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  padding-top: 30px;
}
</style>